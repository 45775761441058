import 'core-js' // https://github.com/zloirock/core-js

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'

import all from './all'

function polyfillIO(): void {
  const s = document.createElement('script')

  s.crossOrigin = 'anonymous'
  s.src =
    'https://polyfill.io/v3/polyfill.min.js?features=default%2Ces2015%2Ces2016%2Ces2017%2Ces2018'

  document.head.appendChild(s)
}

export default {
  init() {
    polyfillIO()
    objectFitImages(null)
    svg4everybody()
    all.init()
  },
}
